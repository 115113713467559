<template>
  <landing-wrapper class="post-assignment">
    <div class="post-assignment__wrapper">
      <post-section>
        <open-booking-info :page-config="pageConfig" />
      </post-section>

      <post-section>
        <my-information-section :organization-name="organizationName"
                                :organizations-list="organizationsList"
                                :fields-config="fieldsConfig" />
      </post-section>

      <!-- <post-section v-if="showBookingTiles"
                    :title="bookingTilesTitle">
        <div class="post-assignment__selection">
          <booking-tile v-for="tile in bookingTiles"
                        :key="tile.title"
                        :data="tile"
                        class="post-assignment__selection-tile" />
        </div>
      </post-section> -->
      <sk-note v-if="$route.name === 'OpenBookingInterpretationNb'"
               :text="personalInfoWarning"
               class="post-assignment__note" />
      <router-view @successsubmit="handleSuccess" />

    </div>
  </landing-wrapper>
</template>

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import constants from '@/modules/constants';
  import PostSection from '@/components/booking_components/PostSection';
  import LandingWrapper from '@/components/landing/LandingWrapper';
  import OpenBookingInfo from '@/components/open_booking/OpenBookingInfo';
  import MyInformationSection from '@/components/open_booking/MyInformation';
  // import OpenBookingTile from '@/components/open_booking/OpenBookingTile';

  export default {
    asyncData({store, route}) {
      let promisesArr = '';
      const organization = helpers.openBookingHelper.getOriginalOpenBookingType(route.params.organization);

      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'language')];
      }
      if (!store.state.selectsData.qualification || !store.state.selectsData.qualification.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'qualification')];
      }
      if (!store.state.PostingStore.SubjectStore || !store.state.PostingStore.SubjectStore.length) {
        promisesArr = [...promisesArr, store.dispatch('PostingStore/SubjectStore/getCategories', {
          isOpenBooking: true,
          organizationName: organization
        })];
      }
      const isRegularOBForOrganizations = helpers.openBookingHelper.openBookingTypes.includes(organization)
        && ['OpenBookingInterpretation', 'OpenBookingTranslation'].includes(route.name);
      const isNbOBForOrganizations = helpers.openBookingHelper.openBookingOrgTypesNb.includes(organization)
        && ['OpenBookingInterpretationNb', 'OpenBookingTranslationNb'].includes(route.name);

      if ((isRegularOBForOrganizations || isNbOBForOrganizations) && !store.state.OpenBookingStore.organizations) {
        promisesArr = [...promisesArr, store.dispatch('OpenBookingStore/getOrganizations')];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    metaInfo() {
      return {
        title: this.pageTitle,
        meta: [
          {vmid: 'description', name: 'description', content: this.pageDescription || this.$getString('metaData', 'description')}
        ]
      };
    },
    components: {
      'open-booking-info': OpenBookingInfo,
      'post-section': PostSection,
      'my-information-section': MyInformationSection,
      'landing-wrapper': LandingWrapper,
      // 'booking-tile': OpenBookingTile
    },
    data() {
      return {
        cookieObjName: `openBooking${this.getOrganizationName(this.$route.params.organization)}`,
        enterpriseOrganizations: ['police', 'municipality', 'health']
      };
    },
    computed: {
      ...mapGetters('OpenBookingStore', ['organizationsList']),
      ...mapState('OpenBookingStore', {
        organizationName: ({organizationName}) => organizationName,
        isExistingCustomer: ({openBooking}) => openBooking.isExistingCustomer,
        email: ({openBooking}) => openBooking.email
      }),
      pageConfig() {
        return this.organizationName
          ? helpers.openBookingFieldConfig(this.organizationName, this)
          : {};
      },
      fieldsConfig() {
        const fieldsConfig = this.isExistingCustomer ? this.pageConfig.existingCustomerFields : this.pageConfig.newCustomerFields;
        return fieldsConfig || {};
      },
      // Do we need this selector and components for translation order?
      // bookingTilesTitle() { return this.$gettext('What would you like to order?'); },
      // showBookingTiles() { return this.pageConfig.translationPage && this.organizationName !== 'nav-translations'; },
      // bookingTiles() {
      //   return [{
      //             type: 'interpretation',
      //             title: this.$gettext('Book an interpreter'),
      //             link: this.$makeRoute({name: this.$route.name === 'OpenBookingTranslation' ? 'OpenBookingInterpretation' : 'OpenBookingInterpretationNb'}),
      //             active: this.$route.meta.type === 'interpretation'
      //           },
      //           {
      //             type: 'translation',
      //             title: this.$gettext('Order translation'),
      //             link: this.$makeRoute({name: this.$route.name === 'OpenBookingInterpretation' ? 'OpenBookingTranslation' : 'OpenBookingTranslationNb'}),
      //             active: this.$route.meta.type === 'translation'
      //           }
      //   ];
      // },
      pageTitle() {
        const domain = this.$getString('domainData', 'platformName');
        const template = this.$gettext('%{domain}\'s platform for booking interpreters for %{organizationName} - %{domain} Interpretation and Translations Services');
        return this.$gettextInterpolate(template, {domain, organizationName: this.fullOrganizationName});
      },
      fullOrganizationName() {
        return helpers.openBookingHelper.getFullOpenBookingName(this, this.organizationName);
      },
      personalInfoWarning() {
        return this.$gettext('Please do not provide any personal information while placing the order. This information is shared with several freelancers as they decide if they would like to take on the assignment.');
      },
      pageDescription() {
        const template = this.$gettext('This page is for %{organization} employees. If you are not employed by %{organization}, please visit our <a class="sk-link" href="/">home page</a> to find the best way for you to book an interpreter.');
        return this.$gettextInterpolate(template, {organization: this.fullOrganizationName});
      }
    },
    methods: {
      ...mapActions('OpenBookingStore', [
        'updateOpenBookingData'
      ]),
      ...mapMutations('OpenBookingStore', [
        'setOrganizationName',
        'setOpenBooking',
        'resetOpenBookingJobInfo'
      ]),
      ...mapMutations('TTAuthStore', ['setResetPasswordEmail']),
      getOrganizationName(organizationName) {
        return helpers.openBookingHelper.getOriginalOpenBookingType(organizationName);
      },
      setUserInfo() {
        const userInfoObj = {
          role: 'employee',
          enterprise: {
            settings: {
              allowedSessionTypes: this.pageConfig.specialSessionTypes || ['video', 'phone', 'in_person'],
              defaultSessionType: 'phone',
              defaultQualificationLevel: '5',
              labelForBookingReference: (this.fieldsConfig.bookingRef && this.fieldsConfig.bookingRef.customName) || this.$gettext('Booking ref.'),
              labelForPaymentBookingReference: (this.fieldsConfig.paymentBookingRef && this.fieldsConfig.paymentBookingRef.customName) || this.$gettext('Invoice ref.'),
              labelForCaseNumber: (this.fieldsConfig.caseNumber && this.fieldsConfig.caseNumber.customName) || this.$gettext('Case number')
            }
          }
        };
        this.$store.commit('UserInfoStore/setUserInfo', {userInfoObj});
        this.$route.meta.type === 'interpretation'
          ? this.$store.dispatch('PostingNotifierStore/preselectInterpretationStaticData')
          : this.$store.dispatch('PostingNotifierStore/preselectProjectStaticData');
      },
      handleSuccess(callback) {
        this.$cookieManager.setCookie(this.cookieObjName, JSON.stringify(this.$store.state.OpenBookingStore.openBooking), {path: '/', expires: constants.COOKIE_EXPIRATION_TIME});
        this.setResetPasswordEmail(this.email);
        callback();
      }
    },
    mounted() {
      this.setOrganizationName(this.getOrganizationName(this.$route.params.organization));
      if ((helpers.openBookingHelper.openBookingTypes.includes(this.organizationName) && constants.SHOW_OPEN_BOOKING === 'true')) {
        this.setOpenBooking(this.organizationName);
        this.setUserInfo();
        const storedOpenBookingData = this.$cookieManager.getCookie(this.cookieObjName) ? JSON.parse(this.$cookieManager.getCookie(this.cookieObjName)) : '';
        this.updateOpenBookingData(storedOpenBookingData);
      } else {
        this.$router.replace(this.$makeRoute({name: 'SignIn'}));
      }
    },
    beforeDestroy() {
      this.$store.commit('OpenBookingStore/clearStore');
    }
  };
</script>

<style scoped>
  .post-assignment {
    padding-top: 60px;
    background-color: #f2f2f2;
  }

  .post-assignment__selection {
    display: flex;
    justify-content: space-between;
  }
</style>
