<template>
  <div class="open-booking-section">
    <div class="post-form__field-group">
      <button :class="{'sk-btn--white': !isExistingCustomer}"
              class="sk-btn sk-btn--default post-form__field post-form__field--half-width"
              @click="catchIsExistingCustomerValue(true)">{{ $gettext('I\'m already a customer') }}</button>
      <button :class="{'sk-btn--white sk-btn--no-hover': isExistingCustomer}"
              class="sk-btn sk-btn--default post-form__field post-form__field--half-width"
              @click="catchIsExistingCustomerValue(false)">{{ $gettext('I\'m a new customer') }}</button>
    </div>
    <div v-if="showLoginLink"
         class="post-form__field-group">
      <a :href="externalLoginPageLink"
         class="sk-link sk-link--default post-form__field">{{ $gettext('Click here to login') }}</a>
    </div>
    <div class="post-form__field-group">
      <sk-select v-if="showOrganizationField"
                 :description="organizationText"
                 :default-value="organizationText"
                 :preselected-value="organization"
                 :validation-name="organizationErrorName"
                 :items-list="computedOrganizationList"
                 class="post-form__field post-form__field--half-width"
                 @csvaluechanged="setOrganization" />
      <sk-select v-if="showDepartmentField"
                 :description="departmentsText"
                 :default-value="departmentsText"
                 :preselected-value="department"
                 :validation-name="departmentErrorName"
                 :items-list="departmentsList"
                 class="post-form__field post-form__field--half-width"
                 @csvaluechanged="setDepartment" />
    </div>
    <div class="post-form__field-group">
      <sk-input :description="firstNameText"
                :preselected-value="firstName"
                :placeholder="firstNameText"
                :validation-name="firstNameErrorName"
                class="post-form__field post-form__field--half-width"
                @fieldvaluechanged="setFirstName" />
      <sk-input :description="lastNameText"
                :preselected-value="lastName"
                :placeholder="lastNameText"
                :validation-name="lastNameErrorName"
                class="post-form__field post-form__field--half-width"
                @fieldvaluechanged="setLastName" />
    </div>
    <div class="post-form__field-group">
      <sk-input :description="emailText"
                :preselected-value="email"
                :placeholder="emailText"
                :validation-name="emailErrorName"
                class="post-form__field post-form__field--half-width"
                @fieldvaluechanged="setEmail"
                @blur="blurEmailField()" />
      <div class="post-form__field post-form__field--half-width open-booking-section__phone-cont">
        <sk-select :type="'countryCodes'"
                   :description="phoneCodeText"
                   :default-value="phoneCodeText"
                   :preselected-value="phoneCode"
                   :validation-name="phoneCodeErrorName"
                   class="open-booking-section__code-select"
                   @csvaluechanged="setPhoneCode" />
        <sk-input :description="phoneNumberText"
                  :preselected-value="phoneNumber"
                  :placeholder="phoneNumberText"
                  :validation-name="phoneNumberErrorName"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  class="open-booking-section__phone-input"
                  @fieldvaluechanged="setPhoneNumber" />
      </div>
    </div>
    <div v-if="showOrgNumber"
         class="post-form__field-group">
      <org-number-field :preselected-value="orgNumber"
                        :description="orgNumberText"
                        :placeholder="orgNumberText"
                        :validation-name="orgNumberErrorName"
                        :max-length="9"
                        :country="'Norway'"
                        class="post-form__field post-form__field--half-width"
                        @fieldvaluechanged="setOrgNumber" />
    </div>
  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from 'vuex';
  import constants from '@/modules/constants';
  import OrgNumberField from '@/components/enterprise/form_parts/OrgNumberField';

  export default {
    components: {
      'org-number-field': OrgNumberField
    },
    props: {
      organizationName: {
        type: String,
        default: ''
      },
      organizationsList: {
        type: Array,
        default: () => []
      },
      fieldsConfig: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        emailFlag: true,
        externalLoginPageLink: this.$getMarketplaceName() === 'salita'
          ? constants.EXTERNAL_LOGIN_PAGE
          : this.$router.resolve(this.$makeRoute({name: 'SignIn'})).href
      };
    },
    computed: {
      ...mapState('OpenBookingStore', {
        departmentsList: ({departmentsList}) => departmentsList || [],
        isExistingCustomer: ({openBooking}) => openBooking.isExistingCustomer,
        firstName: ({openBooking}) => openBooking.firstName,
        lastName: ({openBooking}) => openBooking.lastName,
        email: ({openBooking}) => openBooking.email,
        phoneCode: ({openBooking}) => openBooking.phoneCode,
        phoneNumber: ({openBooking}) => openBooking.phoneNumber,
        organization: ({openBooking}) => openBooking.organization,
        department: ({openBooking}) => openBooking.department,
        orgNumber: ({openBooking}) => openBooking.orgNumber,
        // errors
        firstNameErrorName: ({errors}) => errors.firstName.name,
        lastNameErrorName: ({errors}) => errors.lastName.name,
        emailErrorName: ({errors}) => errors.email.name,
        phoneCodeErrorName: ({errors}) => errors.phoneCode.name,
        phoneNumberErrorName: ({errors}) => errors.phoneNumber.name,
        organizationErrorName: ({errors}) => errors.organization.name,
        departmentErrorName: ({errors}) => errors.departments.name,
        orgNumberErrorName: ({errors}) => errors.orgNumber.name
      }),
      computedOrganizationList() {
        if (this.organizationsList && this.organizationsList.length && !this.isExistingCustomer) {
          const copyList = [...this.organizationsList];
          copyList.push({id: 'other', name: this.$gettext('Other')});
          return copyList;
        }
        return this.organizationsList;
      },
      orgNumberText() { return this.$gettext('Org. number'); },
      firstNameText() { return this.$gettext('First name'); },
      lastNameText() { return this.$gettext('Last name'); },
      emailText() { return this.$gettext('Email'); },
      phoneCodeText() { return this.$gettext('Code'); },
      phoneNumberText() { return this.$gettext('Phone number'); },
      organizationText() { return this.$gettext('Please select your organization'); },
      departmentsText() { return this.$gettext('Choose department'); },
      showOrganizationField() {
        return this.fieldsConfig.organization && this.organizationsList && this.organizationsList.length;
      },
      showDepartmentField() {
        return this.fieldsConfig.department && this.departmentsList && this.departmentsList.length;
      },
      showOrgNumber() { return this.fieldsConfig.orgNumber && (!this.organization || this.organization === 'other'); },
      showLoginLink() { return this.fieldsConfig.loginLink; }
    },
    watch: {
      email() {
        if (this.emailFlag) {
          this.checkEmailExist(this.email);
          this.emailFlag = false;
        }
        return;
      }
    },
    methods: {
      ...mapActions('OpenBookingStore', [
        'catchOrganization',
        'catchDepartment',
        'checkEmailExist'
      ]),
      ...mapMutations('OpenBookingStore', [
        'setFirstName',
        'setLastName',
        'setReferenceCode',
        'setEmail',
        'setPhoneCode',
        'setPhoneNumber',
        'setOrgNumber',
        'setIsExistingCustomerValue'
      ]),
      catchIsExistingCustomerValue(value) {
        this.setIsExistingCustomerValue(value);
        this.setOrgNumber('');
        if (this.isExistingCustomer && this.organization === 'other') this.catchOrganization({enterpriseId: ''});
        setTimeout(this.preselectNonRequiredFields);
      },
      setDepartment(value) {
        this.catchDepartment({departmentId: value, enterpriseId: this.organization, organizationName: this.organizationName});
      },
      setOrganization(value) {
        this.catchOrganization({enterpriseId: value, organizationName: this.organizationName});
      },
      blurEmailField() {
        this.checkEmailExist(this.email);
      }
    }
  };
</script>

<style scoped src="@assets/css/open_booking_section.css"></style>
