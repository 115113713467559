import { render, staticRenderFns } from "./OpenBookingInfo.vue?vue&type=template&id=691ec8dc&scoped=true&"
import script from "./OpenBookingInfo.vue?vue&type=script&lang=js&"
export * from "./OpenBookingInfo.vue?vue&type=script&lang=js&"
import style0 from "@/assets/tikktalk/css/open_booking_info.css?vue&type=style&index=0&id=691ec8dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691ec8dc",
  null
  
)

export default component.exports