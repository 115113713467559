(<template>
  <div class="open-booking-info">
    <h3 class="open-booking-info__title">{{ pageConfig.title }}</h3>
    <div class="open-booking-info__steps">
      <div v-for="step in stepsList"
           :key="step.name"
           :class="[`open-booking-info__step--${step.name}`]"
           class="open-booking-info__step">
        <p class="open-booking-info__step-text"
           v-html="step.text"></p>
      </div>
    </div>
    <p v-for="(item, index) in pageConfig.contentItems"
       :key="index"
       class="open-booking-info__text"
       v-html="item"></p>
  </div>
</template>)

<script>
  export default {
    props: {
      pageConfig: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      stepsList() {
        return [{
          name: 'register',
          text: this.$gettext('1. Submit the form')
        }, {
          name: 'order',
          text: this.$gettext('2. Receive a confirmation')
        }, {
          name: 'perform',
          text: this.$gettext('3. Complete')
        }];
      }
    }
  };
</script>

<style scoped src="@/assets/tikktalk/css/open_booking_info.css"></style>
